body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hexbackground{
    --s:15px; /* shape size */
  --m:0.5px;  /* space */
  
  --v1: rgb(243, 243, 243) 119.5deg, transparent 120.5deg;
  --v2: #fff  119.5deg, transparent 120.5deg;
  background:
    
    conic-gradient(at var(--m)              calc(var(--s)*0.5777), transparent 270deg, #fff 0deg),
    conic-gradient(at calc(100% - var(--m)) calc(var(--s)*0.5777), #fff 90deg,  transparent 0deg),
    
    conic-gradient(from -60deg at 50% calc(var(--s)*0.8662)             , var(--v1)),
    conic-gradient(from -60deg at 50% calc(var(--s)*0.8662 + 2*var(--m)), var(--v2)),
    
    conic-gradient(from 120deg at 50% calc(var(--s)*1.4435 + 3*var(--m)), var(--v1)),
    conic-gradient(from 120deg at 50% calc(var(--s)*1.4435 +   var(--m)), var(--v2)),
    
    linear-gradient(90deg, rgb(243, 243, 243) calc(50% - var(--m)),#fff 0 calc(50% + var(--m)), rgb(243, 243, 243) 0);
    
  background-size: calc(var(--s) + 2*var(--m)) calc(var(--s)*1.732 + 3*var(--m)); 
  background-position:center;
}